import '@usb-shield/react-link/dist/library/styles/index.css'

import USBButton from '@usb-shield/react-button'
import USBLink from '@usb-shield/react-link'
import saoStyle from './solutionsAreaOverview.module.scss'
import { TextBlockWithIconParts } from '@/components/TextBlockWithIcon/textBlockWithIcon.model'
import Protocol from 'devtools-protocol'
import { solutionAreaTreasuryManagmentHandler } from '@/modules/tealium/utilities/events/clickHandlers'
import integer = Protocol.integer

function booleanConversion(value: any) {
  return value === 'false' ? false : !!value
}

const SolutionsAreaOverviewTile = ({ tiles, levelUpHeads }: any) => {
  return tiles.map((tile: TextBlockWithIconParts, index: integer) => {
    return (
      <div
        key={index}
        className={saoStyle.listItem}
        data-testid="solutionsAreaTile"
      >
        {levelUpHeads ? (
          <h2 className={saoStyle.headH2Sao} data-testid={`tile-title${index}`}>
            {tile.title}
          </h2>
        ) : (
          <h3 className={saoStyle.headH3} data-testid={`tile-title${index}`}>
            {tile.title}
          </h3>
        )}
        <p
          className={saoStyle.paragraph}
          data-testid={`tile-shortDescription${index}`}
          dangerouslySetInnerHTML={{ __html: tile.shortDescription }}
        />

        {booleanConversion(tile.linkAsButton) ? (
          <USBButton
            variant="primary"
            size="default"
            addClasses={saoStyle.buttonSao}
            handleClick={() => {
              window.open(tile.ctaLink, '_self')
            }}
          >
            {tile.ctaText}
          </USBButton>
        ) : (
          tile.ctaLink && (
            <span className={saoStyle.linkLayout}>
              <USBLink
                linkType="basic"
                href={tile.ctaLink}
                dataTestId={`tile-ctaLink`}
                addClasses={saoStyle.solutionAreaLink}
                handleClick={solutionAreaTreasuryManagmentHandler}
              >
                {tile.ctaText}
              </USBLink>
            </span>
          )
        )}
      </div>
    )
  })
}

export default SolutionsAreaOverviewTile
