import '@usb-shield/react-grid/dist/library/styles/index.css'
import '@usb-shield/react-link/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import React from 'react'
import SolutionsAreaOverviewTile from '@/components/SolutionsAreaOverview/SolutionsAreaOverviewTile'
import USBButton from '@usb-shield/react-button'
import { defaultGridOptions } from '@/utils/usb-grid.util'
import saoStyle from '@/components/SolutionsAreaOverview/solutionsAreaOverview.module.scss'
import { useMediaQuery } from '@/utils/usb-media-query.util'
import {
  SolutionsAreaOverviewModel,
  GetOverviewImageParams,
} from '@/components/SolutionsAreaOverview/SolutionsAreaOverview.model'
import { getOverviewImage } from '@/components/SolutionsAreaOverview/utils/imageUtils'

const fourColsOption = {
  span: null,
  spans: {
    large: 4,
    medium: 4,
    small: 4,
  },
  offset: {
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const imageColumn = {
  ...fourColsOption,
  spans: {
    xlarge: 4,
    large: 6,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
}

const eightColsOption = {
  span: null,
  spans: {
    xlarge: 8,
    large: 8,
    medium: 6,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 2,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const twelveColsOption = {
  span: null,
  spans: {
    xlarge: 12,
    large: 12,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 2,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start',
}

const textColumn = {
  ...twelveColsOption,
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
  },
}

const solAreaColumn = {
  ...eightColsOption,
  spans: {
    xlarge: 8,
    large: 10,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
}

const SolutionsImageLeftHorizText = ({
  solutionArea,
}: {
  solutionArea: SolutionsAreaOverviewModel
}) => {
  const domain: string = process.env.CONTENT_DOMAIN || '',
    imageSrc = domain.concat(solutionArea.image || ''),
    video = solutionArea.video || ''

  let isPageWide = useMediaQuery('(min-width: 760px) and (max-width: 1055px)'),
    isMobileDevice = useMediaQuery('(min-width: 250px) and (max-width: 759px)'),
    getImageParams: GetOverviewImageParams = {
      isPageWide: isPageWide,
      isMobileDevice: isMobileDevice,
      imageSrc: imageSrc,
      videoSrc: video,
    },
    img = getOverviewImage(getImageParams)

  return (
    <section id="SolutionsImageLeftHorizText" className={saoStyle.sectionRow}>
      <USBGrid
        gridGap={defaultGridOptions.gridGap}
        alignItems={defaultGridOptions.alignItems}
        columnCount={defaultGridOptions.columnCount}
        justifyContent={defaultGridOptions.justifyContent}
        display={defaultGridOptions.display}
        padding={defaultGridOptions.padding}
        addClasses={saoStyle.gridWrapper}
      >
        <USBColumn layoutOpts={textColumn}>
          <h2
            className={saoStyle.headH2}
            data-testid="solutionOverviewHeading"
            dangerouslySetInnerHTML={{ __html: solutionArea.title }}
          ></h2>
          <p
            className={saoStyle.paragraph}
            dangerouslySetInnerHTML={{ __html: solutionArea.shortDescription }}
            data-testid="solutionOverviewSubHeading"
          ></p>
        </USBColumn>
        <USBColumn layoutOpts={imageColumn} addClasses={saoStyle.overviewImage}>
          {img}
        </USBColumn>
        <USBColumn
          layoutOpts={solAreaColumn}
          addClasses={saoStyle.overviewList}
        >
          {/* Add SolutionsAreaOverviewTile */}
          <SolutionsAreaOverviewTile
            tiles={solutionArea.textBlockWithImageParts}
            addClasses={saoStyle.overviewList}
            levelUpHeads={!solutionArea.title}
          />
          {solutionArea.ctaText &&
            solutionArea.ctaText !== '' &&
            solutionArea.ctaLink &&
            solutionArea.ctaLink !== '' && (
              <USBButton
                variant="secondary"
                size="default"
                spacing={{ margin: 0 }}
                addClasses={saoStyle.solutionButton}
                dataTestId="solutionButton"
                handleClick={(e: any) => {
                  window.open(solutionArea.ctaLink, '_blank')
                }}
              >
                {' '}
                {solutionArea.ctaText}
              </USBButton>
            )}
        </USBColumn>
      </USBGrid>
    </section>
  )
}

export default SolutionsImageLeftHorizText
